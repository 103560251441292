import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import { HeartIcon } from "@heroicons/react/outline"
// import { Fragment } from "react"

import { Hero, Cards } from "../components/template"
import Seo from "../components/seo"
// import { GatsbyImage } from "gatsby-plugin-image"
export default function Page({
  pageContext: { data },
  data: {
    allSanityCar: { nodes: cars },
  },
}) {
  // console.log(cars)
  return (
    <Layout>
      <Seo
        title={data.title || data.name}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      <Hero property={data} />
      <Cards className="pt-12" template link="/cars/" list={cars} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityCar(sort: { order: ASC, fields: order }) {
      nodes {
        name
        highPrice
        lowPrice
        slug {
          _key
          _type
          current
        }
        cardImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
